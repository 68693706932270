import React from 'react';

function InsightsApp() {
    return (
        <div>   
            <iframe
                title="Insights"
                src="https://shamba.users.earthengine.app/view/insightstool"
                width="100%"
                height="700px"
            ></iframe>
        </div>
    );
}

export default InsightsApp;
