import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { useAccount } from './AccountContext'; // Import the context
function ContractDeployment() {

    const { currentAccount, setCurrentAccount } = useAccount(); // Use the context

    // State variables to store form inputs of insurance provider deployment
    const [insuranceProviderName, setInsuranceProviderName] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    // State variables to store form inputs of insurance contract deployment
    const [isLoadingInsuranceContractCreation, setIsLoadingInsuranceContractCreation] = useState(false)
    const [clientWalletAddress, setClientWalletAddress] = useState('');
    const [payoutAmount, setPayoutAmount] = useState(0);
    const [payoutAmountInUsd, setPayoutAmountInUsd] = useState('');
    const [payoutAmountInXrp, setPayoutAmountInXrp] = useState('');
    const [payoutAmountInUsdLabel, setPayoutAmountInUsdLabel] = useState('');
    const [payoutAmountInXrpLabel, setPayoutAmountInXrpLabel] = useState('');
    const [totalPayoutAmount, setTotalPayoutAmount] = useState(0);
    const [totalPayoutAmountInUsd, setTotalPayoutAmountInUsd] = useState('');
    const [totalPayoutAmountInXrp, setTotalPayoutAmountInXrp] = useState('');
    const [totalPayoutAmountInUsdLabel, setTotalPayoutAmountInUsdLabel] = useState('');
    const [totalPayoutAmountInXrpLabel, setTotalPayoutAmountInXrpLabel] = useState('');
    const [labelText, setLabelText] = useState("KES ");
    const [geostatsThreshold, setGeostatsThreshold] = useState(0);
    const [requestIpfsCid, setRequestIpfsCid] = useState('');
    const [mpesaNumbersList, setMpesaNumbersList] = useState([]);
    const [file, setFile] = useState('null');
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const rootUrl = 'https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api'
    const secretKey = "abedb5608c232cb7e621f1c1228d8474bb54589776b530cd302360f2870ffc1f"

    const getContractABI = async (contractAddress) => {

        const ABI_url = `https://evm-sidechain.xrpl.org/api?module=contract&action=getabi&address=${contractAddress}`

        try {
            const response = await axios.get(ABI_url)
            return { "abi": JSON.parse(response.data.result) }
        } catch (error) {
            console.error('There was an error!', error)
            return { "Error": error.message }
        }
    }

    const kesToUsd = (amount) => {
        try {
            return axios.get("https://open.er-api.com/v6/latest/KES").then((result) => {
                if (result.status == 200) {
                    return amount * result.data.rates.USD
                }
                else {
                    return 0
                }

            })
        }
        catch (err) {
            return 0
        }

    }

    const apiUrl = 'https://api.coingecko.com/api/v3/simple/price';
    const params = {
        ids: 'ripple',
        vs_currencies: 'usd'
    };

    const usdToXrp = (amount) => {
        try {
            return axios.get(apiUrl, { params })
                .then(response => {
                    if (response.status == 200) {
                        return amount * (1 / response.data.ripple.usd)
                    }
                    else {
                        return 0
                    }

                })
        }
        catch (err) {
            return 0
        }

    }

    const payoutAmountChangeHandler = (event) => {


        if (event.target.value) {
            setTotalPayoutAmount(event.target.value)
            kesToUsd(parseFloat(event.target.value)).then((amountInUsd) => {
                if (amountInUsd) {
                    setTotalPayoutAmountInUsdLabel(amountInUsd.toFixed(2))
                    setTotalPayoutAmountInUsd(amountInUsd.toFixed(2).toString())
                    usdToXrp(amountInUsd).then((amountInXrp) => {
                        if (amountInXrp) {
                            setTotalPayoutAmountInXrpLabel(amountInXrp.toFixed(2))
                            setTotalPayoutAmountInXrp(amountInXrp.toFixed(2).toString())
                        }
                        else {
                            setTotalPayoutAmountInXrpLabel('')
                            setTotalPayoutAmountInXrp('')
                        }
                    })
                }
                else {
                    setTotalPayoutAmountInUsdLabel('')
                    setTotalPayoutAmountInUsd('')
                }
            })

            if (mpesaNumbersList && mpesaNumbersList.length) {

                setPayoutAmount(Math.floor(parseFloat(event.target.value) / mpesaNumbersList.length))

                kesToUsd(Math.floor(parseFloat(event.target.value) / mpesaNumbersList.length)).then((amountInUsd) => {
                    if (amountInUsd) {
                        setPayoutAmountInUsdLabel(amountInUsd.toFixed(2))
                        setPayoutAmountInUsd(amountInUsd.toFixed(2).toString())
                        usdToXrp(amountInUsd).then((amountInXrp) => {
                            if (amountInXrp) {
                                setPayoutAmountInXrpLabel(amountInXrp.toFixed(2))
                                setPayoutAmountInXrp(amountInXrp.toFixed(2).toString())
                            }
                            else {
                                setPayoutAmountInXrpLabel('')
                                setPayoutAmountInXrp('')
                            }
                        })
                    }
                    else {
                        setPayoutAmountInUsdLabel('')
                        setPayoutAmountInUsd('')
                    }
                })
            }

        }
        else {
            setTotalPayoutAmount(0)
            setTotalPayoutAmountInUsd('')
            setTotalPayoutAmountInUsdLabel('')
            setTotalPayoutAmountInXrp('')
            setTotalPayoutAmountInXrpLabel('')

            setPayoutAmount(0)
            setPayoutAmountInUsd('')
            setPayoutAmountInUsdLabel('')
            setPayoutAmountInXrp('')
            setPayoutAmountInXrpLabel('')

        }

    }


    const handleFileChange = (e) => {

        if (e.target.files && e.target.files[0]) {

            const file = e.target.files[0];

            if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type == 'text/csv') {

                const fileReader = new FileReader();

                fileReader.onload = e => {
                    const target = e.target;
                    const result = target?.result;

                    let readedData = XLSX.read(result, { type: 'binary' });

                    const wsname = readedData.SheetNames[0];
                    const ws = readedData.Sheets[wsname];

                    const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

                    var numbers = []

                    dataParse.slice(1).map((data) => numbers.push(data[0].toString()))

                    var pretty = JSON.stringify({ "numbers": numbers }, undefined, 2);

                    // console.log(pretty)


                    if (numbers.length && numbers.length !== new Set(numbers).size) {
                        alert("The file is having duplicate numbers. Please make sure that each number appears only once.")
                    }
                    else {
                        setMpesaNumbersList(numbers)

                        if (totalPayoutAmount) {
                            console.log('here')
                            setPayoutAmount(Math.floor(totalPayoutAmount / numbers.length))

                            kesToUsd(Math.floor(totalPayoutAmount / numbers.length)).then((amountInUsd) => {
                                if (amountInUsd) {
                                    setPayoutAmountInUsdLabel(amountInUsd.toFixed(2))
                                    setPayoutAmountInUsd(amountInUsd.toFixed(2).toString())
                                    usdToXrp(amountInUsd).then((amountInXrp) => {
                                        if (amountInXrp) {
                                            setPayoutAmountInXrpLabel(amountInXrp.toFixed(2))
                                            setPayoutAmountInXrp(amountInXrp.toFixed(2).toString())
                                        }
                                        else {
                                            setPayoutAmountInXrpLabel('')
                                            setPayoutAmountInXrp('')
                                        }
                                    })
                                }
                                else {
                                    setPayoutAmountInUsdLabel('')
                                    setPayoutAmountInUsd('')
                                }
                            })
                        }

                    }


                };

                fileReader.readAsBinaryString(file);
            }
            else {
                alert('Not a valid file type. Please select either .csv or .xlsx file.')
            }
        }
    };

    const addInsuranceProviderToDB = async (insuranceProviderData) => {

        const url = `${rootUrl}/addInsuranceProvider`

        const config = {
            headers: {
                secret: secretKey,
            }
        };

        try {
            const response = await axios.post(url, insuranceProviderData, config)
            return response.status
        } catch (error) {
            console.error('There was an error!', error)
            return { "Error": error.message }
        }
    }

    const addInsuranceContractToDB = async (insuranceContractData) => {

        const url = `${rootUrl}/addInsuranceContract`
        // const url = `http://127.0.0.1:8000/addInsuranceContract`

        const config = {
            headers: {
                secret: secretKey,
            }
        };

        try {
            const response = await axios.post(url, insuranceContractData, config)
            console.log(response.status)
            return response.status
        } catch (error) {
            console.error('There was an error!', error)
            return { "Error": error.message }
        }
    }

    // Function to handle form submission
    const handleContractDeployment = async (e) => {
        e.preventDefault(); // Prevent the form from submitting (for now)

        // You can add your smart contract deployment logic here
        // Example: Deploy the contract using web3.js or a suitable library

        try {
            const { ethereum } = window

            if (ethereum) {
                console.log('here')

                if (selectedProvider == '') {
                    alert("Please select an Insurance Product from the dropdown.")
                }

                else if (!ethers.utils.isAddress(clientWalletAddress)) {
                    alert("Please enter a valid client's wallet address.")
                }
                else if (totalPayoutAmount <= 0) {
                    alert("Please enter a valid total payout amount.")
                }
                else if (geostatsThreshold == 0) {
                    alert("Please enter a valid geostats threshold.")
                }
                else if (requestIpfsCid == '') {
                    alert("Please enter a valid IPFS CID.")
                }
                else if (mpesaNumbersList.length == 0) {
                    alert("Please upload an excel or csv file containing at least one number for MPESA Payment.")
                }
                else {
                    axios.get(`https://${requestIpfsCid}.ipfs.dweb.link`).then(async (res) => {
                        const requestBody = res.data
                        if (!Object(requestBody).hasOwnProperty("agg_x")) {
                            alert("Request body stored on IPFS is not having 'agg_x' field.")
                        }
                        else if (!Object(requestBody).hasOwnProperty("dataset_code")) {
                            alert("Request body stored on IPFS is not having 'dataset_code' field.")
                        }
                        else if (!Object(requestBody).hasOwnProperty("selected_band")) {
                            alert("Request body stored on IPFS is not having 'selected_band' field.")
                        }
                        else if (!Object(requestBody).hasOwnProperty("start_date")) {
                            alert("Request body stored on IPFS is not having 'start_date' field.")
                        }
                        else if (!Object(requestBody).hasOwnProperty("end_date")) {
                            alert("Request body stored on IPFS is not having 'end_date' field.")
                        }
                        else if (!Object(requestBody).hasOwnProperty("image_scale")) {
                            alert("Request body stored on IPFS is not having 'image_scale' field.")
                        }
                        else if (!Object(requestBody).hasOwnProperty("geometry")) {
                            alert("Request body stored on IPFS is not having 'geometry' field.")
                        }
                        else {
                            console.log(clientWalletAddress, totalPayoutAmountInXrp, ethers.utils.parseUnits(geostatsThreshold).toString(), requestIpfsCid)
                            const provider = new ethers.providers.Web3Provider(ethereum)
                            const signer = provider.getSigner()
                            const chainId = parseInt((await provider.getNetwork()).chainId.toString())

                            console.log(chainId)

                            console.log(selectedProvider)

                            const GeoInsuranceProviderAbi = await getContractABI(selectedProvider)

                            const GeoInsuranceProvider = new ethers.Contract(
                                selectedProvider,
                                GeoInsuranceProviderAbi.abi,
                                signer
                            )
                            setIsLoadingInsuranceContractCreation(true)

                            // console.log(parseFloat(payoutAmount) + parseFloat(payoutAmount) / 100)
                            // console.log(ethers.utils.parseUnits((parseFloat(payoutAmountInXrp) + parseFloat(payoutAmountInXrp) / 100).toString()).toString())

                            GeoInsuranceProvider.deployInsuranceContract(
                                clientWalletAddress,
                                ethers.utils.parseUnits(totalPayoutAmountInXrp).toString(),
                                ethers.utils.parseUnits(geostatsThreshold).toString(),
                                requestIpfsCid,
                                { value: ethers.utils.parseUnits((parseFloat(totalPayoutAmountInXrp) + parseFloat(totalPayoutAmountInXrp) / 100).toString()).toString() }

                            ).then((createdInsuranceContractTx) => {
                                createdInsuranceContractTx.wait().then(async (rc) => {
                                    const event = rc.events.find(event => event.event === 'contractCreated');
                                    // console.log(event)

                                    // ethChainId: insuranceContractInfo.ethChainId,        
                                    // insurerAddress: insuranceContractInfo.insurerAddress,
                                    // clientAddress: insuranceContractInfo.clientAddress,
                                    // insuranceProviderAddress: insuranceContractInfo.insuranceProviderAddress,
                                    // insuranceContractAddress: insuranceContractInfo.insuranceContractAddress,
                                    // requestIpfsCid: insuranceContractInfo.requestIpfsCid,
                                    // requestBody: insuranceContractInfo.requestBody,
                                    // payoutAmountInNativeToken: insuranceContractInfo.payoutAmountInNativeToken



                                    console.log(selectedProvider)
                                    const insuranceContractData = {
                                        "ethChainId": chainId,
                                        "insurerAddress": (await signer.getAddress()).toLowerCase(),
                                        "clientAddress": clientWalletAddress.toLowerCase(),
                                        "insuranceProviderAddress": selectedProvider,
                                        "insuranceContractAddress": event.args[0],
                                        "requestIpfsCid": requestIpfsCid,
                                        "requestBody": requestBody,
                                        "geostatsThreshold": geostatsThreshold,
                                        "mpesaPhoneNumbers": mpesaNumbersList,
                                        "payoutAmountInNativeToken": totalPayoutAmountInXrp,
                                        "payoutAmountInUSD": totalPayoutAmountInUsd,
                                        "payoutAmountInKES": totalPayoutAmount,
                                        "payoutAmountPerPersonInNativeToken": payoutAmountInXrp,
                                        "payoutAmountPerPersonInUSD": payoutAmountInUsd,
                                        "payoutAmountPerPersonInKES": payoutAmount
                                    }

                                    // console.log(insuranceContractData)

                                    addInsuranceContractToDB(insuranceContractData).then((addInsuranceContractToDBStatus) => {
                                        if (addInsuranceContractToDBStatus == 200) {
                                            setIsLoadingInsuranceContractCreation(false)
                                            alert("Insurance Contract at " + event.args[0] + " has been created.")
                                        }
                                        else {
                                            setIsLoadingInsuranceContractCreation(false)
                                            alert("Error while creating the Insurance Contract. Please try again.")
                                        }
                                    }).catch((err) => {
                                        setIsLoadingInsuranceContractCreation(false)
                                        alert("Error while creating the Insurance Contract. Please try again.")
                                    })


                                }).catch((err) => {
                                    setIsLoadingInsuranceContractCreation(false)
                                    alert("Error while creating the Insurance Contract. Please try again.")
                                })
                            }).catch((err) => {
                                setIsLoadingInsuranceContractCreation(false)
                                alert("Error while creating the Insurance Contract. Please try again.")
                            })
                        }

                    }).catch((err) => {
                        console.log(err)
                        alert("Please enter a valid IPFS CID.")
                    })

                }

            } else {
                console.log("ethereum object doesn't exist")
            }
        } catch (error) {
            console.log(error)
        }
    };

    const isInsuranceProviderWithTheSameNameExistInDB = async (insuranceProviderData) => {
        const url = `${rootUrl}/isInsuranceProviderWithTheSameNameExist`
        try {
            const response = await axios.post(url, insuranceProviderData)
            if (response.status == 200) {
                return response.data.message
            }
            else {
                throw "Invalid Boolean Data"
            }
        } catch (error) {
            console.error('There was an error!', error)
            return { "Error": error.message }
        }
    }

    // Function to handle form submission
    const handleInsuranceProviderDeployment = async (e) => {
        e.preventDefault();

        try {
            const { ethereum } = window

            if (ethereum) {
                console.log('here')

                if (insuranceProviderName == '') {
                    alert("Please enter a valid Insurance Product Name.")
                }
                else {

                    const insuranceProviderData = {
                        "insuranceProviderName": insuranceProviderName,
                    }

                    isInsuranceProviderWithTheSameNameExistInDB(insuranceProviderData).then(async (isInsuranceProviderWithTheSameNameExist) => {

                        if (isInsuranceProviderWithTheSameNameExist) {
                            alert("Insurance Product with the same name (case insensitive) already exists. Please enter a different name.")
                        }
                        else {
                            const contractAbi = await getContractABI("0xF95Fffa50e7CD5b9592766bAa81c6991aCfDf3E2")

                            const provider = new ethers.providers.Web3Provider(ethereum)
                            const signer = provider.getSigner()
                            const chainId = parseInt((await provider.getNetwork()).chainId.toString())


                            const transaction = await provider.getTransaction(
                                "0x8f7a3ed602bdcdbcc3ca1370116eacde9df69c50180a2bf57c8dce259358c441"
                            );

                            // console.log(contractAbi)

                            const abi = ethers.utils.defaultAbiCoder;

                            // const abi = ethers.utils.defaultAbiCoder;

                            console.log(abi)

                            const originalConstructorParamValue = 1440002;
                            const encodedParameters = abi
                                .encode(["uint256"], [originalConstructorParamValue])
                                .slice(2);

                            const byteCode = transaction.data.slice(0, -encodedParameters.length);

                            console.log(byteCode)

                            let factory = new ethers.ContractFactory(contractAbi["abi"], byteCode, signer);

                            let contract = await factory.deploy(originalConstructorParamValue);

                            // let contractAddress = await contract.getAddress();

                            let contractAddress = contract.address;

                            // console.log(contract.deployTransaction.hash);

                            setIsLoading(true)

                            contract.deployed().then(async (value) => {

                                const insuranceProviderData = {
                                    "ethChainId": chainId,
                                    "insurerAddress": (await signer.getAddress()).toLowerCase(),
                                    "insuranceProviderName": insuranceProviderName,
                                    "insuranceProviderAddress": contractAddress,
                                }


                                addInsuranceProviderToDB(insuranceProviderData).then((addInsuranceProviderToDBStatus) => {
                                    if (addInsuranceProviderToDBStatus == 200) {

                                        setIsLoading(false)
                                        alert(`Insurance Product has been deployed at ${contractAddress}`)


                                        ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {

                                            fetch(`${rootUrl}/getInsuranceProviders?insurerAddress=${accounts[0]}`)
                                                .then((response) => response.json())
                                                .then((data) => {
                                                    setProviders(data.data);
                                                    //console.log('Privder data', data)
                                                })
                                                .catch((error) => {
                                                    console.error('Error fetching data:', error);
                                                });
                                        })

                                    }
                                    else {
                                        setIsLoading(false)
                                        alert("Error while deploying the Insurance Product. Please try again.")
                                    }
                                })

                            }).catch((err) => {
                                setIsLoading(false)
                                alert("Error while deploying the Insurance Product. Please try again.")
                            })

                        }
                    })


                }

            } else {
                console.log("ethereum object doesn't exist")
            }
        } catch (error) {
            console.log(error)
        }


    }
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
                console.log('Found account', accounts[0])
                setCurrentAccount(accounts[0])
                // Fetch data from your API endpoint
                fetch(`${rootUrl}/getInsuranceProviders?insurerAddress=${accounts[0]}`)
                    .then((response) => response.json())
                    .then((data) => {
                        setProviders(data.data);
                        console.log('Privder data', data)
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error);
                    });
            })


            window.ethereum.on('chainChanged', () => {
                window.location.reload();
            })
            window.ethereum.on('accountsChanged', () => {

                const { ethereum } = window

                ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {

                    fetch(`${rootUrl}/getInsuranceProviders?insurerAddress=${accounts[0]}`)
                        .then((response) => response.json())
                        .then((data) => {
                            setProviders(data.data);
                            console.log('Privder data', data)
                        })
                        .catch((error) => {
                            console.error('Error fetching data:', error);
                        });
                })

            })


        }

    }, []);

    const handleOptionChange = (e) => {
        // console.log(e.target.value)
        setSelectedProvider(e.target.value);
    };



    return (
        <div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-row justify-content-between">
                                {/* <h4 className="card-title mb-1">Insurance Providers</h4> */}

                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <form class="forms-sample" onSubmit={handleInsuranceProviderDeployment}>
                                                <div class="form-group">
                                                    <label>Insurance Product Name:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter insurance Product name"
                                                        value={insuranceProviderName}
                                                        onChange={(e) => setInsuranceProviderName(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                {isLoading ? <div>Deploying...</div> : <button type="submit" id="deployprovider" className='btn btn-primary mr-2'>Deploy Insurance Product</button>}
                                            </form>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Select Insurance Product</h4>
                                            <select onChange={handleOptionChange} class="form-control">
                                                <option value="">--------------</option>
                                                {providers
                                                    ? providers.map((provider) => (
                                                        <option key={provider.insuranceProviderAddress} value={provider.insuranceProviderAddress}>
                                                            {provider.insuranceProviderName}
                                                        </option>
                                                    ))
                                                    : <option>Loading...</option>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-12">

                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Contract Deployment</h4>
                                            <form class="forms-sample" onSubmit={handleContractDeployment}>
                                                <div class="form-group">
                                                    <label>Client Address:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter client address"
                                                        value={clientWalletAddress}
                                                        onChange={(e) => setClientWalletAddress(e.target.value)}
                                                        required
                                                    />

                                                </div>
                                                <div class="form-group">
                                                    <label>Upload Excel or CSV File (containing MPESA numbers of beneficiaries):</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        placeholder="A file containing phone numbers"
                                                        onChange={handleFileChange}
                                                        required
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label>Total Payout (in Kenyan Shillings):</label>
                                                    <div id="gap_container">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter total payout amount (KES)"
                                                            value={totalPayoutAmount}
                                                            onChange={payoutAmountChangeHandler}
                                                            required
                                                        />
                                                    </div>
                                                    <div>
                                                        <span style={{ float: 'right' }}>{totalPayoutAmountInUsdLabel == '' ? totalPayoutAmountInUsdLabel : totalPayoutAmountInUsdLabel + ' USD'}</span>
                                                        <span>{totalPayoutAmountInXrpLabel == '' ? totalPayoutAmountInXrpLabel : totalPayoutAmountInXrpLabel + ' XRP'}</span>
                                                    </div>

                                                </div>
                                                <div class="form-group">
                                                    <label>Payout Amount Per Person (in Kenyan Shillings):</label>
                                                    <div id="gap_container">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Payout amount per person (KES)"
                                                            value={payoutAmount}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div>
                                                        <span style={{ float: 'right' }}>{payoutAmountInUsdLabel == '' ? payoutAmountInUsdLabel : payoutAmountInUsdLabel + ' USD'}</span>
                                                        <span>{payoutAmountInXrpLabel == '' ? payoutAmountInXrpLabel : payoutAmountInXrpLabel + ' XRP'}</span>
                                                    </div>

                                                </div>
                                                <div class="form-group">
                                                    <label>Threshold:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter trigger threshold"
                                                        value={geostatsThreshold}
                                                        onChange={(e) => setGeostatsThreshold(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label>IPFS CID:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter acquired IPFS CID"
                                                        value={requestIpfsCid}
                                                        onChange={(e) => setRequestIpfsCid(e.target.value)}
                                                        required
                                                    />
                                                </div>



                                                {isLoadingInsuranceContractCreation ? <div className="insurance-contract-creation-loading-container">
                                                    <div className="insurance-contract-creation-loading-spinner"></div>
                                                    <div className='insurance-contract-creation-loading-label'>Creating Insurance Contract...</div>
                                                </div> : <button type="submit" className='btn btn-primary mr-2'>Deploy Contract</button>}
                                                {/* <button class="btn btn-dark">Cancel</button> */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ContractDeployment;
