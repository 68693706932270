import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Map from './Map';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Logo from './Logo';


function Sidebar() {
    const location = useLocation();
    const logoPath = `assets/images/logo.png`;
    

    return (
        <div>
            <nav class="sidebar sidebar-offcanvas" id="sidebar">
                <div class="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                    <a class="sidebar-brand brand-logo" href=""><Logo /></a>
                    <a class="sidebar-brand brand-logo-mini" href=""><Logo /></a>
                </div>
                <ul class="nav">

                   
                    <li class="nav-item menu-items px-4 py-3">
                        <NavLink to="/" className={({isActive,isPending}) => isPending ? "nav-link pending": isActive ? "nav-link active": "nav-link"}>
                            <span class="menu-icon">
                                <i class="mdi mdi-speedometer"></i>
                            </span>
                            <span class="menu-title">Home</span>
                        </NavLink>
                    </li>
                    <li class="nav-item menu-items px-4 py-3">
                        <NavLink to="/map" className={({ isActive, isPending }) => isPending ? "nav-link pending" : isActive ? "nav-link active" : "nav-link"}>
                            <span class="menu-icon">
                                <i class="mdi mdi-map"></i>
                            </span>
                            <span class="menu-title">Map</span>
                        </NavLink>
                    </li>  
                    <li class="nav-item menu-items px-4 py-3">
                        <NavLink to="/specifications" className={({ isActive, isPending }) => isPending ? "nav-link pending" : isActive ? "nav-link active" : "nav-link"}>
                            <span class="menu-icon">
                                <i class="mdi mdi-table-large"></i>
                            </span>
                            <span class="menu-title">Specifications</span>
                        </NavLink>
                    </li>
                    <li class="nav-item menu-items px-4 py-3">
                        <NavLink to="/deploy" className={({ isActive, isPending }) => isPending ? "nav-link pending" : isActive ? "nav-link active" : "nav-link"}>
                            <span class="menu-icon">
                                <i class="mdi mdi-table-large"></i>
                            </span>
                            <span class="menu-title">Deploy</span>
                        </NavLink>

                    </li>
                    <li class="nav-item menu-items px-4 py-3">
                        <NavLink to="/insurance-providers" className={({ isActive, isPending }) => isPending ? "nav-link pending" : isActive ? "nav-link active" : "nav-link"}>
                            <span class="menu-icon">
                                <i class="mdi mdi-table-large"></i>
                            </span>
                            <span class="menu-title">Products</span>
                        </NavLink>

                    </li>
                    <li class="nav-item menu-items px-4 py-3">
                        <NavLink to="/insurance-contracts" className={({ isActive, isPending }) => isPending ? "nav-link pending" : isActive ? "nav-link active" : "nav-link"}>
                            <span class="menu-icon">
                                <i class="mdi mdi-table-large"></i>
                            </span>
                            <span class="menu-title">Contracts</span>
                        </NavLink>
                    </li>
                    <li class="nav-item menu-items px-4 py-3">
                        <NavLink to="/insights" className={({isActive,isPending}) => isPending ? "nav-link pending": isActive ? "nav-link active": "nav-link"}>
                            <span class="menu-icon">
                                <i class="mdi mdi-playlist-play"></i>
                            </span>
                            <span class="menu-title">Insights</span>
                        </NavLink>
                    </li>
                    <li class="nav-item menu-items px-4 py-3">
                        <NavLink to="/data" className={({ isActive, isPending }) => isPending ? "nav-link pending" : isActive ? "nav-link active" : "nav-link"}>
                            <span class="menu-icon">
                                <i class="mdi mdi-playlist-play"></i>
                            </span>
                            <span class="menu-title">Data</span>
                        </NavLink>
                    </li>
                   
                    
                   
                   
                    
                    {/* <li class="nav-item menu-items px-4 py-3">
                        <NavLink to="/documentation" className={({ isActive, isPending }) => isPending ? "nav-link pending" : isActive ? "nav-link active" : "nav-link"}>
                            <span class="menu-icon">
                                <i class="mdi mdi-file-document-box"></i>
                            </span>
                            <span class="menu-title">Documentation</span>
                        </NavLink>
                    </li>  */}
                   
                </ul>
            </nav>


        </div>
    );
}


export default Sidebar