import React, { useState, useEffect } from 'react';
import { Deck, _GlobeView as GlobeView } from '@deck.gl/core';
import { SolidPolygonLayer, GeoJsonLayer, IconLayer, ArcLayer } from '@deck.gl/layers';
import { DeckGL } from 'deck.gl';
import { Link } from 'react-router-dom';
import Legend from './Legend';
import './map.css';

import { useAccount } from './AccountContext'; // Import the context




// source: Natural Earth http://www.naturalearthdata.com/ via geojson.xyz
const COUNTRIES = 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_scale_rank.geojson'; //eslint-disable-line
const rootUrl = "https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api";

const INITIAL_VIEW_STATE = {
    latitude: 10.281104,
    longitude: 32.751666,
    zoom: 3
};




export default function Map({ data, filteredData, selectedAddress }) {

    const { currentAccount, setCurrentAccount } = useAccount(); // Use the context
    const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
    const galaxyColors = [
        '#220033',
        '#330055',
        '#440077',
        '#550099',
        '#6600BB',
        '#7700DD',
        '#8800FF',
        '#7700DD',
        '#6600BB',
        '#550099',
        '#440077',
        '#330055',
        '#220033',
    ];

    const legendData = [
        { label: 'Countries', color: [147, 77, 158], layer: 'base-map' },
        { label: 'Project Areas', color: [255, 159, 0, 180], layer: 'projects-layer' },
        { label: 'Project Points', color: [0, 255, 0, 180], layer: 'projects-marker-layer' }
    ];
    const [projectGeoData, setProjectGeoData] = useState(null);
    const [popupInfo, setPopupInfo] = useState(null);
    // Compute centroid of a simple polygon
    const computeCentroid = (coordinates) => {
        let sumX = 0;
        let sumY = 0;
        const len = coordinates[0].length; // Assuming your polygons are simple and without holes

        coordinates[0].forEach(coord => {
            sumX += coord[0];
            sumY += coord[1];
        });

        return [sumX / len, sumY / len];
    };
    useEffect(() => {

        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
                console.log('Found account', accounts[0])
                setCurrentAccount(accounts[0])
                // Fetch data from your API endpoint
                fetch(`${rootUrl}/getInsuranceContracts?insurerAddress=${accounts[0]}`)
                .then(response => response.json())
                .then(data => {
                    const transformedData = {
                        type: 'FeatureCollection',
                        features: data.data.map(project => {
                            const geometry = project.requestBody.geometry.features[0].geometry;
                            const centroid = geometry.type === 'Polygon' ? computeCentroid(geometry.coordinates) : null;
                            return {
                                type: 'Feature',
                                geometry: geometry,
                                properties: {
                                    ...project.requestBody.geometry.features[0].properties,
                                    insuranceContractAddress: project.insuranceContractAddress,
                                    contractName: project.location,
                                    insurerAddress: project.insurerAddress,
                                    requestIpfsCid: project.requestIpfsCid,
                                    ethChainId: project.ethChainId,
                                    centroid: centroid
                                }
                            }
                        })
                    };
                    setProjectGeoData(transformedData);
                })
                .catch((error) => {
                        console.error('Error fetching data:', error);
                });
            })


            window.ethereum.on('chainChanged', () => {
                window.location.reload();
            })
            window.ethereum.on('accountsChanged', () => {

                const { ethereum } = window

                ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {

                    fetch(`${rootUrl}/getInsuranceContracts?insurerAddress=${accounts[0]}`)
                .then(response => response.json())
                .then(data => {
                    const transformedData = {
                        type: 'FeatureCollection',
                        features: data.data.map(project => {
                            const geometry = project.requestBody.geometry.features[0].geometry;
                            const centroid = geometry.type === 'Polygon' ? computeCentroid(geometry.coordinates) : null;
                            return {
                                type: 'Feature',
                                geometry: geometry,
                                properties: {
                                    ...project.requestBody.geometry.features[0].properties,
                                    insuranceContractAddress: project.insuranceContractAddress,
                                    contractName: project.location,
                                    insurerAddress: project.insurerAddress,
                                    requestIpfsCid: project.requestIpfsCid,
                                    ethChainId: project.ethChainId,
                                    centroid: centroid
                                }
                            }
                        })
                    };
                    setProjectGeoData(transformedData);
                })
                .catch((error) => {
                        console.error('Error fetching data:', error);
                });
                })

            })


        }
        // fetch(projects_data)
            // .then(response => response.json())
            // .then(data => {
            //     const transformedData = {
            //         type: 'FeatureCollection',
            //         features: data.data.map(project => {
            //             const geometry = project.requestBody.geometry.features[0].geometry;
            //             const centroid = geometry.type === 'Polygon' ? computeCentroid(geometry.coordinates) : null;
            //             return {
            //                 type: 'Feature',
            //                 geometry: geometry,
            //                 properties: {
            //                     ...project.requestBody.geometry.features[0].properties,
            //                     insuranceContractAddress: project.insuranceContractAddress,
            //                     contractName: project.location,
            //                     insurerAddress: project.insurerAddress,
            //                     requestIpfsCid: project.requestIpfsCid,
            //                     ethChainId: project.ethChainId,
            //                     centroid: centroid
            //                 }
            //             }
            //         })
            //     };
            //     setProjectGeoData(transformedData);
            // });
    }, []);
    useEffect(() => {
        if (selectedAddress && data) {
            const selectedFeature = data.find(
                feature => feature.properties.insuranceContractAddress === selectedAddress
            );

            if (selectedFeature && selectedFeature.geometry.type === 'Polygon') {
                const [longitude, latitude] = computeCentroid(selectedFeature.geometry.coordinates);
                setViewState(prev => ({
                    ...prev,
                    latitude,
                    longitude,
                    zoom: 6 // Set to a desired zoom level for a feature
                }));
            } // Additional geometry types can be added if necessary

        }
    }, [selectedAddress, data]);

    function getTooltip({ object }) {

        if (object && object.properties) {

            return (
                object && {
                    html: `\
                <div><b>Name</b></div>
                <div><a href="/contract/${object.properties.insuranceContractAddress}">${object.properties.contractName}</a></div> 
                <div><b>Contract</b></div>
                <div>${object.properties.insuranceContractAddress}</div>                
                <div><b>IPFS Reference</b></div>
                <div>${object.properties.requestIpfsCid}</div>
                `
                }
            );
        }

        return null
    }
    const renderPopup = () => {
        return popupInfo && (
            <div
                style={{
                    position: 'absolute',
                    zIndex: 1,
                    left: popupInfo.x,
                    top: popupInfo.y,
                    pointerEvents: 'auto', // Ensure pointer events are enabled
                }}
            >
                <div style={{ background: '#22242F', padding: '10px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0,0,0,0.5)' }}>
                    <div>
                        <div><b>Name</b></div>
                        <div>
                            <a href={`/contract/${popupInfo.object.properties.insuranceContractAddress}`}>
                                {popupInfo.object.properties.contractName}
                            </a>
                        </div>
                        <div><b>Contract</b></div>
                        <div>{popupInfo.object.properties.insuranceContractAddress}</div>
                        <div><b>IPFS Reference</b></div>
                        <a href={`https://${popupInfo.object.properties.requestIpfsCid}.ipfs.dweb.link`} target='blank'>{popupInfo.object.properties.requestIpfsCid}</a>
                    </div>
                    <br />
                    <button onClick={(e) => { e.stopPropagation(); setPopupInfo(null); }}>Close</button>
                    <a href={`/contract/${popupInfo.object.properties.insuranceContractAddress}`} target="_blank">
                        <button>View More</button>
                    </a>
                </div>
            </div>
        );
    }

    const controlStyles = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 2,
        fontSize: '28px',
        display: 'flex',
        flexDirection: 'column', // stack buttons vertically
        gap: '5px'  // space between buttons
    };
    function zoomIn() {
        setViewState(prevState => ({
            ...prevState,
            zoom: prevState.zoom + 1
        }));
    }

    function zoomOut() {
        setViewState(prevState => ({
            ...prevState,
            zoom: prevState.zoom - 1
        }));
    }

    // Define the icon URL and size
    const iconURL = 'https://bafybeiaxkc2f2ouluwaedfrz56bhsrauqgztfqegt3jhyltcdqnnxpu6ui.ipfs.w3s.link/icons8-location-pin-64.png'
    //'https://bafybeievujalxwz2pqxf2tcsm27p62gkiwmsjw327nxlfa5mxxecjlxoui.ipfs.w3s.link/icon.png'
    //'https://img.icons8.com/dusk/64/000000/user-location.png'
    
    const iconSize = [64, 64]; // Specify the size of the icon

    return (
        <div style={{ background: '#09101D' }} class="vector-map">
            <div style={controlStyles}>
                <button onClick={zoomIn}>+</button>
                <button onClick={zoomOut}>-</button>

            </div>
            {renderPopup()}
            <div id="map" style={{ left: '12px', top: '0px', width: '100%', position: 'absolute', height: '100%' }}>

                <DeckGL
                    onClick={(info, event) => {
                        // If you click on anything other than a feature, hide the popup.
                        if (!info.object) {
                            setPopupInfo(null);
                        }
                        event.stopPropagation();
                    }}
                    views={new GlobeView()}
                    viewState={viewState}
                    onViewStateChange={({ viewState }) => setViewState(viewState)}

                    controller={true}
                    getTooltip={getTooltip}

                    layers={[
                        new SolidPolygonLayer({
                            id: 'background',
                            data: [
                                // prettier-ignore
                                [[-180, 90], [0, 90], [180, 90], [180, -90], [0, -90], [-180, -90]]
                            ],
                            opacity: 0.9,
                            getPolygon: d => d,
                            stroked: true,
                            filled: true,
                            getFillColor: galaxyColors,
                        }),
                        new GeoJsonLayer({
                            id: 'base-map',
                            data: COUNTRIES,
                            // Styles
                            stroked: true,
                            filled: true,
                            lineWidthMinPixels: 2,
                            getLineColor: [116, 50, 130],
                            getFillColor: [31, 38, 42]
                        }),       

                        projectGeoData && projectGeoData.features ? projectGeoData.features.map((feature, index) => {

                            return new IconLayer({
                                id: `icon-layer-${index}`,
                                data: [
                                    {
                                        type: 'Feature',
                                        position: feature.properties.centroid,                               
                                        properties: feature.properties
                                    }
                                ],
                                pickable: true,
                                iconAtlas: iconURL,
                                iconMapping: {
                                    marker: {
                                        x: 0,
                                        y: 0,
                                        width: iconSize[0],
                                        height: iconSize[1],
                                    },
                                },
                                getPosition: (d) => d.position,
                                getIcon: (d) => "marker",
                                getSize: 5, // Adjust as needed
                                sizeScale: 5, // Adjust as needed
                                onClick: info => {
                                    // console.log(info)
                                    setPopupInfo(info);
                                },
                                visible: viewState.zoom <= 5
                            });
                        }) : [],


                        new GeoJsonLayer({
                            id: 'projects-layer',
                            data: projectGeoData,
                            pickable: true,
                            stroked: true,
                            filled: false,
                            pointType: 'circle',
                            lineWidthScale: 40,
                            lineWidthMinPixels: 4,
                            getLineColor: [255, 159, 0, 100],
                            getFillColor: [0, 35, 100, 0],
                            getPointRadius: 300,
                            getLineWidth: 10,
                            visible: viewState.zoom > 5,
                            onClick: (info, event) => {
                                setPopupInfo(info);
                                event.stopPropagation();
                            }
                        })

                    ]}
                />
            </div>
            {/* <Legend data={legendData} position="bottom-right" title="Legend" /> */}
        </div>

    )
}