// App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import Sidebar from '../components/Sidebar';
import NavBar from '../components/NavBar';
import InsuranceProviders from '../components/InsuranceProvider';
import TopSummary from '../components/TopSummary';
import HomeSummary from '../components/HomeSummary';

const InsuranceProvidersList = () => {
    return (
        <div>

            <div className="container-scroller">
                <Sidebar />
                <div className="container-fluid page-body-wrapper">
                    <NavBar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            < HomeSummary />
                            <InsuranceProviders />
                            <TopSummary />
                            

                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default InsuranceProvidersList;