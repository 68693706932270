import React from 'react';

function Specifications() {
    return (
        <div>
            <iframe
                title="Insights"
                src="https://contracts.shamba.app/"
                width="100%"
                height="700px"
            ></iframe>
        </div>
    );
}

export default Specifications;
