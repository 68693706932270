import React, { useState, useEffect,useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Map from './Map';
import { AccountProvider, AccountContext } from './AccountContext';

function HomeSummary() {
    const [data, setData] = useState([]);
    const [paidAmount, setPaidAmount] = useState(0);
    const [heldAmount, setHeldAmount] = useState(0);
    const [totalContracts, setTotalContracts] = useState(0);
    const [activeCount, setActiveCount] = useState(0);
    const [inactiveCount, setInactiveCount] = useState(0);
    const { currentAccount, setCurrentAccount } = useContext(AccountContext);

    useEffect(() => {
        // Fetch data from your API endpoint
        fetch(`https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api/getInsuranceContracts?insurerAddress=${currentAccount}`)
            .then((response) => response.json())
            .then((data) => {
                setData(data);
                // Process data to count Paid and Unpaid
                const paid = data.data.filter((item) => item.insuranceStatus === "PAID");
                const held = data.data.filter((item) => item.insuranceStatus === "NOT PAID");
                const sumPaidAmount = paid.reduce((total, item) => total + item.payoutAmountInKES, 0);
                const sumHeldAmount = held.reduce((total, item) => total + item.payoutAmountInKES, 0);
                const active = data.data.filter((item) => item.isContractActive === true);
                const inactive = data.data.filter((item) => item.isContractActive === false);

                setPaidAmount(sumPaidAmount);
                setHeldAmount(sumHeldAmount);
                setActiveCount(active.length);
                setInactiveCount(inactive.length);
                setTotalContracts(data.data.length)
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    return (
        <div>
            <div class="row">
                <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-9">
                                    <div class="d-flex align-items-center align-self-start">
                                        <h3 class="mb-0">KES {paidAmount}</h3>
                                       
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="icon icon-box-success ">
                                        <span class="mdi mdi-cash icon-item"></span>
                                    </div>
                                </div>
                            </div>
                            <h6 class="text-muted font-weight-normal">Total Paid</h6>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-9">
                                    <div class="d-flex align-items-center align-self-start">
                                        <h3 class="mb-0">KES {heldAmount}</h3>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="icon icon-box-warning">
                                        <span class="mdi mdi-cash-multiple icon-item"></span>
                                    </div>
                                </div>
                            </div>
                            <h6 class="text-muted font-weight-normal">Amounts Pending</h6>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-9">
                                    <div class="d-flex align-items-center align-self-start">
                                        <h3 class="mb-0">{totalContracts}</h3>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="icon icon-box-danger">
                                        <span class="mdi mdi-file-document-edit  icon-item"></span>
                                    </div>
                                </div>
                            </div>
                            <h6 class="text-muted font-weight-normal">Deployed Contracts</h6>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-9">
                                    <div class="d-flex align-items-center align-self-start">
                                        <h3 class="mb-0">{activeCount}/{inactiveCount}</h3>
                                        
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="icon icon-box-info ">
                                        <span class="mdi mdi-circle-slice-2 icon-item"></span>
                                    </div>
                                </div>
                            </div>
                            <h6 class="text-muted font-weight-normal">Active/Inactive Contracts</h6>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}


export default HomeSummary