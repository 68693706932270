import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount } from './AccountContext'; // Import the context
import Logo from './Logo';

function NavBar() {
    const { currentAccount, setCurrentAccount } = useAccount(); // Use the context
    const [correctNetwork, setCorrectNetwork] = useState(true)
    const location = useLocation();
    const logoPath = `assets/images/logo.png`;

    const connectWallet = async () => {
        try {
            const { ethereum } = window

            if (!ethereum) {
                alert('Metamask wallet not detected')
                return
            }
            let chainId = await ethereum.request({ method: 'eth_chainId' })
            console.log('Connected to chain:' + chainId)

            const xrplEvmSidechain = '0x15f902'

            if (chainId !== xrplEvmSidechain) {
                // alert('You are not connected to the XRPL EVM Sidechain!')'
                setCorrectNetwork(false)
                return
            } else {
                setCorrectNetwork(true)
            }

            const accounts = await ethereum.request({ method: 'eth_requestAccounts' })

            console.log('Found account', accounts[0])
            setCurrentAccount(accounts[0])
        } catch (error) {
            console.log('Error connecting to metamask', error)
        }
    }

    return (
        <div>
            <nav className="navbar p-0 fixed-top d-flex flex-row">
                <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
                    <a className="navbar-brand brand-logo-mini" href="/"><Logo /></a>
                </div>
                <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                        <span className="mdi mdi-menu"></span>
                    </button>


                    <ul className="navbar-nav navbar-nav-right">
                        <li className="nav-item dropdown">
                            <a className="nav-link" id="profileDropdown" href="#" data-toggle="dropdown">
                                <div className="navbar-profile">
                                    {currentAccount ? (
                                        <p className="mb-0 d-none d-sm-block navbar-profile-name">{currentAccount.substring(0, 5) + '....' + currentAccount.substring(currentAccount.length-4, currentAccount.length)}</p>
                                    ) : (
                                        <a className="nav-link btn btn-primary create-new-button" id="createbuttonDropdown" data-toggle="dropdown" aria-expanded="false" href="#" onClick={connectWallet}>
                                            + Connect Wallet
                                        </a>
                                    )}
                                </div>
                            </a>
                        </li>

                        <li className="nav-item nav-settings d-none d-lg-block">
                            <a className="nav-link" href="#">
                                <i className="mdi mdi-view-grid"></i>
                            </a>
                        </li>

                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas" >
                        <span className="mdi mdi-format-line-spacing"></span>
                    </button>
                </div>
            </nav>

           
        </div>
    );
}

export default NavBar;
