import React, { useEffect, useState, useContext } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AccountProvider, AccountContext } from './AccountContext';

function Settlement() {
    const [chartOptions, setChartOptions] = useState({});
    const [available, setAvailable] = useState({});
    const { currentAccount, setCurrentAccount } = useContext(AccountContext);

    useEffect(() => {
        // Fetch data from your API
        fetch(`https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api/getInsuranceContracts?insurerAddress=${currentAccount}`)
            .then((response) => response.json())
            .then((data) => {
                // Filter the data for records with 'Paid' status
                const paidRecords = data.data.filter((record) => record.insuranceStatus === 'PAID');

                // Count the number of paid records for each date
                const paidRecordCounts = paidRecords.reduce((counts, record) => {
                    const date = record.settlementDate; // Replace with your actual date field name
                    counts[date] = (counts[date] || 0) + 1;
                    return counts;
                }, {});

                // Create an array of objects with date and count
                const dateCategories = Object.entries(paidRecordCounts).map(([date, count]) => ({
                    date: new Date(date).toLocaleDateString(),
                    count,
                }));
                const groupedCounts = dateCategories.reduce((result, item) => {
                    const existingItem = result.find((x) => x.date === item.date);
                    if (existingItem) {
                        existingItem.count += item.count;
                    } else {
                        result.push(item);
                    }
                    return result;
                }, []);
                const chartData = groupedCounts.map(({ date, count }) => [date, count]);
                if (groupedCounts.length > 0){
                    setAvailable(true);
                }
                console.log('records', groupedCounts)

                // Create Highcharts options for the column chart
                const options = {
                    chart: {
                        type: 'column',
                        backgroundColor: '#191C24',

                    },
                    title: {
                        text: 'Settled Contracts Over Time',
                        style: {
                            color: 'white',
                            fontWeight: 'bold'
                        }
                    },
                    credits: {
                        "enabled": false
                    },
                    legend: {
                        "enabled": false
                    },
                    xAxis: {
                        categories: chartData.map(dataPoint => dataPoint[0]),
                        labels: {
                            useHTML: true,
                            style: {
                                color: '#666666'
                            }
                        },
                        lineColor: '#666666',
                    },
                    yAxis: {
                        title: {
                            text: 'Count',
                        },
                        labels: {
                            useHTML: true,
                            style: {
                                color: '#666666'
                            }
                        },
                        lineColor: '#666666',
                    },
                    series: [
                        {
                            name: 'Contracts',
                            data: chartData,
                        },
                    ],
                };

                setChartOptions(options);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div>
            {available ? (
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            ) : (
                <p>No data available yet!"</p>
            )}
            
        </div>
    );
}

export default Settlement;
