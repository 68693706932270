import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContractMap from '../components/ContractsMap';

function Contract() {
    const { id } = useParams();
    const [contract, setContract] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [centrePointDerived, setCentrePoint] = useState(null);
    const centrePoint = [2.783768343031249, 37.49087114932809];


    useEffect(() => {
        fetch(`https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api/getInsuranceContracts`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the response as JSON
            })
            .then((data) => {
                const filteredContract = data.data.find((item) => item.insuranceContractAddress === id);
                setContract(filteredContract);// Update the state with the data
                console.log('Contracts', filteredContract);
                setIsLoading(false);
                if (filteredContract) {
                    let lat = filteredContract.centrePoint[0];
                    let long = filteredContract.centrePoint[1];
                    let updatedCenter = [long, lat];
                    console.log('Updated centers', updatedCenter);
                    setCentrePoint(updatedCenter);
                }
            })
            .catch((error) => {
                console.error('Error fetching contract details:', error);
                setIsLoading(false);
            });
    }, [id]);
    return (
        <div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">

                            <div className="row">
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Address: {id} </h4>
                                            <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                                <div className="text-md-center text-xl-left">
                                                    <h6 className="mb-1">Description</h6>
                                                    <p className="text-muted mb-0">{contract.description}</p>
                                                </div>

                                                <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">

                                                    <h6 className="font-weight-bold mb-0"> {contract.isWhitelisted ? (
                                                        <div className="badge badge-outline-primary">Whitelisted</div>
                                                    ) : (
                                                        <div className="badge badge-outline-warning">Not Whitelisted</div>
                                                    )} </h6>
                                                </div>
                                            </div>
                                            <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                                <div className="text-md-center text-xl-left">
                                                    <h6 className="mb-1">Location</h6>
                                                    <p className="text-muted mb-0">{contract.location}</p>
                                                </div>
                                                <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                                                    <h6 className="mb-1">Payout Status</h6>
                                                    <p className="text-muted mb-0">{contract.insuranceStatus}</p>
                                                </div>
                                            </div>
                                            <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                                <div className="text-md-center text-xl-left">
                                                    <h6 className="mb-1">Geostats Threshold</h6>
                                                    <p className="text-muted mb-0">{contract.geostatsThreshold}</p>
                                                </div>
                                                <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                                                    <h6 className="mb-1">Geostats from Oracle</h6>
                                                    <p className="text-muted mb-0">{contract.geostatsFromOracle}</p>
                                                </div>
                                            </div>
                                            <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                                <div className="text-md-center text-xl-left">
                                                    <h6 className="mb-1">Date Created</h6>
                                                    <p className="text-muted mb-0">{new Date(contract.createDate).toLocaleString()}</p>
                                                </div>
                                                <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                                                    <h6 className="mb-1">Payout Amount</h6>
                                                    <p className="text-muted mb-0">{contract.payoutAmountInKES} KES</p>
                                                </div>
                                            </div>
                                            <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                                <div className="text-md-center text-xl-left">
                                                    <h6 className="mb-1">Period</h6>
                                                    <p className="text-muted mb-0">{contract && contract.requestBody && (
                                                        <p>{contract.requestBody.start_date} to {contract.requestBody.end_date}</p>
                                                    )}</p>
                                                </div>

                                                <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                                                    <h6 className="mb-1">Status</h6>
                                                    <h6 className="font-weight-bold mb-0"> {contract.isContractActive ? (
                                                        <div className="badge badge-outline-success">Active</div>
                                                    ) : (
                                                        <div className="badge badge-outline-danger">Not Active</div>
                                                    )} </h6>
                                                </div>
                                            </div>
                                            <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                                <div className="text-md-center text-xl-left">
                                                    <h6 className="mb-1">Web3 Transaction Details</h6>
                                                    <p className="text-muted mb-0">{contract && contract.requestBody && (
                                                        <a href={contract.web3TransactionExplorerUrl} target='blank'>{contract.web3TransactionHash}</a>
                                                    )}</p>
                                                </div>


                                            </div>
                                            
                                            <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                                <div className="text-md-center text-xl-left">
                                                    <h6 className="mb-1">MPESA Beneficiaries</h6>
                                                    <div className="table-responsive">
                                                        <table className="table">

                                                            <thead>
                                                                <tr>
                                                                    <th>Phone Number</th>
                                                                    <th>Amount (KES)</th>
                                                                    <th>Reference Number</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* <tr>
                                                                    <td>{contract.mpesaPhoneNumbers ? contract.mpesaPhoneNumbers[0] : ''}</td>
                                                                    <td>{contract.payoutAmountInKES}</td>
                                                                    <td>RJU4X7YIQG</td>
                                                                </tr> */}

                                                                {contract.mpesaPhoneNumbers ? contract.mpesaPhoneNumbers.map((listValue, index) => {
                                                                    console.log(listValue)
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{listValue}</td>
                                                                            <td>{contract.payoutAmountPerPersonInKES}</td>
                                                                            <td>{contract.mpesaTransactionDetails ? contract.mpesaTransactionDetails[listValue].txResponse : contract.insuranceStatus == 'PAID' ? 'NOT PAID YET' : 'NOT PAID'}</td>
                                                                        </tr>
                                                                    );
                                                                }): <div></div>}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Map </h4>
                                            <hr></hr>
                                            {centrePointDerived ? (
                                                <ContractMap centrePoints={centrePointDerived} />
                                            ) : (
                                                <p>Not available</p>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contract;
