// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Specifications from '../components/Specifications';
import Sidebar from '../components/Sidebar';
import NavBar from '../components/NavBar';


function SpecificationsPage() {
    return (
        <div>
            <div class="container-scroller">
                <Sidebar />
                <div class="container-fluid page-body-wrapper">
                    <NavBar />
                    <div class="main-panel">
                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">                                           
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <Specifications />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default SpecificationsPage;