import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAccount } from './AccountContext'; // Import the context


function ContractsList() {
    const { id } = useParams();
    const [contracts, setContracts] = useState([]);
    const [isLoadingContracts, setIsLoadingContracts] = useState(true);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [providers, setProviders] = useState([]);
    const [isLoadingProviders, setIsLoadingProviders] = useState(true);

    const { currentAccount, setCurrentAccount } = useAccount(); // Use the context

    const rootUrl = 'https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api'

    const handleOptionChange = (e) => {
        // console.log(e.target.value)
        setSelectedProvider(e.target.value);

        fetch(`${rootUrl}/getInsuranceContracts?insuranceProviderAddress=${e.target.value}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the response as JSON
            })
            .then((data) => {
                setContracts(data); // Update the state with the data
                setIsLoadingContracts(false);
                // console.log('This is data', data);
            })
            .catch((error) => {
                console.error('Error fetching insurance contracts:', error);
                setIsLoadingContracts(false);
            });
    };

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
                console.log('Found account', accounts[0])
                setCurrentAccount(accounts[0])
                
                // Fetch data from your API endpoint
                fetch(`${rootUrl}/getInsuranceProviders?insurerAddress=${accounts[0]}`)
                    .then((response) => response.json())
                    .then((data) => {
                        setProviders(data);
                        setIsLoadingProviders(false)
                        console.log('Privder data', data)
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error);
                        setIsLoadingProviders(false)
                    });
            })


            window.ethereum.on('chainChanged', () => {
                window.location.reload();
            })
            window.ethereum.on('accountsChanged', () => {
                setContracts(null)

                const { ethereum } = window

                ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {

                    fetch(`${rootUrl}/getInsuranceProviders?insurerAddress=${accounts[0]}`)
                        .then((response) => response.json())
                        .then((data) => {
                            setProviders(data);
                            setIsLoadingProviders(false)
                        })
                        .catch((error) => {
                            console.error('Error fetching data:', error);
                            setIsLoadingProviders(false)
                        });
                })

            })


        }
        
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-row justify-content-between">
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div>
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="card-title">Select Insurance Product</h4>
                                                <select onChange={handleOptionChange} class="form-control">
                                                    <option value="">--------------</option>
                                                    {providers.data && Array.isArray(providers.data)
                                                        ? providers.data.map((provider) => (
                                                            <option
                                                                key={provider.insuranceProviderAddress}
                                                                value={provider.insuranceProviderAddress}
                                                            >
                                                                {provider.insuranceProviderName}
                                                            </option>
                                                        ))
                                                        : <option>Loading...</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Insurance Contracts</h4>

                                            <div className="table-responsive">
                                                {!selectedProvider || !contracts ? <p>Please select Insurance Product from the dropdown</p> : isLoadingContracts ? (
                                                    <p>Loading...</p>
                                                ) : contracts.data.length > 0 ? (
                                                    <table className="table table-hover">

                                                        <thead>
                                                            <tr>
                                                                <th>Contract Name</th>
                                                                {/* <th>Address</th> */}
                                                                <th>Dates</th>
                                                                <th>Status</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {contracts.data.map((contract) => (
                                                                <tr key={contract.insuranceContractAddress}>
                                                                    <td><Link to={`/contract/${contract.insuranceContractAddress}`}>{contract.title}</Link></td>
                                                                    {/* <td>{provider.insuranceContractAddress}</td> */}
                                                                    <td>{contract.requestBody.start_date} to {contract.requestBody.end_date}</td>
                                                                    <td>
                                                                        {contract.insuranceStatus === 'PAID' && (
                                                                            <div className="badge badge-outline-success">PAID</div>
                                                                        )}
                                                                        {contract.insuranceStatus === 'NOT PAID' && (
                                                                            <div className="badge badge-outline-warning">NOT PAID</div>
                                                                        )}
                                                                    </td>


                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p>No contracts available.</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}


export default ContractsList