import React, { useState, useEffect } from 'react';
import L from 'leaflet'; // Import the Leaflet library


function ContractMap({ centrePoints }) {
    const [map, setMap] = useState(null);
    console.log('Centers derived',centrePoints);
   

    useEffect(() => {
        const mapInstance = L.map('mapid').setView(centrePoints, 5); // Adjust the zoom level
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attributionControl: false
        }).addTo(mapInstance);
        L.marker(centrePoints).addTo(mapInstance);
        setMap(mapInstance);
        

        // Clean up the map when the component unmounts
        return () => {
            mapInstance.remove();
        };
    }, [centrePoints]);

    return (
        <div id="mapid" style={{ height: '400px' }}></div>
    );
}




export default ContractMap;
