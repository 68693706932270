// App.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import Map from './components/Map';
import Sidebar from './components/Sidebar';
import NavBar from './components/NavBar';
import TopSummary from './components/TopSummary';
import ContractSummary from './components/ContractSummary';
import TokenSummary from './components/HomeSummary';
import MapPage from './pages/MapPage';
import Home from './pages/Home';
import InsightPage from './pages/InsightsPage';
import SpecificationsPage from './pages/SpecificationsPage';
import InsuranceProvidersList from './pages/InsuranceProviderPage';
import InsuranceContractsList from './pages/InsuranceContractsPage';
import ProviderDetail from './pages/ProviderDetailPage';
import ContractDetails from './pages/ContractPage';
import InsuranceProvidersListing from './pages/InsuranceProviderListPage';
import InsuranceContractsListing from './pages/ContractsListPage';
import DocsPage from './pages/DocumentationPage';
import Deployment from './pages/DeployContractsPage';
import { AccountProvider, AccountContext } from './components/AccountContext';
import Modal from './components/Modal';
// import Contracts from './pages/Contracts';
const projects_data = "https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-ecofutures-api/getEcoFuturesContracts";


const BlockBima = () => {
  const { currentAccount, setCurrentAccount } = useContext(AccountContext);
  const [correctNetwork, setCorrectNetwork] = useState(true)
  const shouldShowApp = currentAccount && setCurrentAccount !== undefined;
  console.log('Current account and logged in', currentAccount, setCurrentAccount)

  const connectWallet = async () => {
    try {
      const { ethereum } = window

      if (!ethereum) {
        alert('Metamask wallet not detected')
        return
      }
      let chainId = await ethereum.request({ method: 'eth_chainId' })

      const xrplEvmSidechain = '0x15f902'

      if (chainId !== xrplEvmSidechain) {
        setCorrectNetwork(false)
        // alert('You are not connected to the XRPL EVM Sidechain!')
      } else {
        setCorrectNetwork(true)
      }

      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })

      setCurrentAccount(accounts[0])
    } catch (error) {
      console.log('Error connecting to metamask', error)
    }
  }

  return (
    <div>
      {shouldShowApp && correctNetwork ? (
        <div>
          <BrowserRouter>
            <Routes>
              {/* <Route path="/" exact element={<Home />} /> */}
              <Route exact path="/" element={<InsuranceProvidersList />} />
              <Route path="/provider/:id" element={<ProviderDetail />} />
              <Route path="/contract/:id" element={<ContractDetails />} />
              <Route path="/insurance-contracts" element={<InsuranceContractsListing />} />
              <Route path="/insurance-providers" element={<InsuranceProvidersListing />} />
              <Route path="/map" element={<MapPage />} />
              <Route path="/deploy" element={<Deployment />} />
              <Route path="/insights" element={<InsightPage />} />
              <Route path="/specifications" element={<SpecificationsPage />} />
              <Route path="/data" element={<InsuranceProvidersList />} />
              {/* <Route path="/documentation" element={<DocsPage />} /> */}

            </Routes>
          </BrowserRouter>
        </div>
      ) : !correctNetwork ?

        <Modal message="You are not connected to the XRPL EVM Sidechain." />
        : (
          <Modal message="You need to connect your wallet before continuing." onClickAction={connectWallet} />
        )

      }
    </div>
  );
};


function App() {
  const [projectData, setProjectData] = useState([]);


  return (
    <div>
      <AccountProvider>
        <BlockBima />
      </AccountProvider>

    </div>
  );
}

export default App;