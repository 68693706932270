import React, { useEffect, useState, useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { AccountProvider, AccountContext } from './AccountContext';

const Status = () => {
  const [contracts, setContracts] = useState([]);
  const [providers, setProviders] = useState([]);
  const { currentAccount, setCurrentAccount } = useContext(AccountContext);

  useEffect(() => {
    // Define the URLs of the APIs you want to fetch data from
    const apiUrl1 = `https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api/getInsuranceContracts?insurerAddress=${currentAccount}`;
    const apiUrl2 = `https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api/getInsuranceProviders?insurerAddress=${currentAccount}`;

    // Use Promise.all to fetch data from both APIs in parallel
    Promise.all([fetch(apiUrl1), fetch(apiUrl2)])
      .then((responses) => Promise.all(responses.map((response) => response.json())))
      .then((data) => {
        setContracts(data[0].data.length); // Data from API 1
        setProviders(data[1].data.length); // Data from API 2
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  console.log('Actives', contracts, providers)
    const pieChartOptions = {
        chart: {
            type: "pie",
        backgroundColor: '#191C24',
        },
        title: {
            text: "Contracts vs. Products",
            style: {
              color: 'white',
              fontWeight: 'bold'
            }
        },
        credits: {
          "enabled": false
        },
        plotOptions: {
          pie: {
            size: '100%',
            dataLabels: {
              enabled: true,
              distance: 5,
            }
          }
        },
        series: [
            {
                name: "",
                data: [
                    {
                        name: "Contracts",
                        y: contracts,
                        // sliced: true,
                        color: "#009c6b", // Specify the color using a hex code
                    },
                    {
                        name: "Products",
                        y: providers,
                        color: "#d2691e", // Specify the color using RGB values
                    },
                ],
            },
        ],
    };


  return (
      <div>
      {contracts || providers ? (
        <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
      ) : (
        <p>No data available yet!"</p>
      )}
      </div>
  );
};

export default Status;
