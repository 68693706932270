import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Map from './Map';
import Payout from '../components/PayoutStatusChart';
import Status from '../components/Status';
import Settlement from '../components/SettlmentStatus';



function TopSummary() {

    return (
        <div>
            <div class="row">
                <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <Payout />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <Status />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-sm-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <Settlement />
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>
                </div>
              
            </div>

        </div>
    );
}


export default TopSummary