import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
export const AccountContext = createContext();

// Custom hook to access the context
export function useAccount() {
    return useContext(AccountContext);
}


export function AccountProvider({ children }) {
    const [currentAccount, setCurrentAccount] = useState(null);
    const [correctNetwork, setCorrectNetwork] = useState(true)
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('chainChanged', () => {
                window.location.reload();
            })
            window.ethereum.on('accountsChanged', () => {

                const { ethereum } = window

                ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
                    setCurrentAccount(accounts[0])
                })

            })


        }

    }, [])
    const connectWallet = async () => {
        try {
            const { ethereum } = window

            if (!ethereum) {
                alert('Metamask wallet not detected')
                return
            }
            let chainId = await ethereum.request({ method: 'eth_chainId' })
            console.log('Connected to chain:' + chainId)

            const xrplEvmSidechain = '0x15f902'

            if (chainId !== xrplEvmSidechain) {
                // alert('You are not connected to the XRPL EVM Sidechain!')
                setCorrectNetwork(false)
                return
            } else {
                setCorrectNetwork(true)
            }

            const accounts = await ethereum.request({ method: 'eth_requestAccounts' })

            console.log('Found account', accounts[0])
            setCurrentAccount(accounts[0])
        } catch (error) {
            console.log('Error connecting to metamask', error)
        }
    }

    // Define other context values or functions as needed

    return (
        <AccountContext.Provider value={{ currentAccount, setCurrentAccount }}>
            {children}
        </AccountContext.Provider>
    );
}
