import React, { useEffect, useState, useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { AccountProvider, AccountContext } from './AccountContext';


const Payout = () => {
  const [data, setData] = useState([]);
  const [paidCount, setPaidCount] = useState(0);
  const [unpaidCount, setUnpaidCount] = useState(0);
  const { currentAccount, setCurrentAccount } = useContext(AccountContext);

  useEffect(() => {
    // Fetch data from your API endpoint
    fetch(`https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api/getInsuranceContracts?insurerAddress=${currentAccount}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        // console.log('This is returned data',currentAccount, data)

        // Process data to count Paid and Unpaid
        const paid = data.data.filter((item) => item.insuranceStatus === "PAID");
        const unpaid = data.data.filter((item) => item.insuranceStatus === "NOT PAID");

        setPaidCount(paid.length);
        setUnpaidCount(unpaid.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
    const pieChartOptions = {
        chart: {
            type: "pie",
        backgroundColor: '#191C24',
        },
        title: {
            text: "Settled vs. Unsettled Payouts",
          style: {
            color: 'white',
            fontWeight: 'bold'
          }
        },
        credits: {
          "enabled": false
        },
        plotOptions: {
          pie: {
            size: '100%',
            dataLabels: {
              enabled: true,
              distance: -60,
            }
          }
        },
        series: [
            {
                name: "",
                data: [
                    ["Settled", paidCount],
                    ["Unsettled", unpaidCount],
                ],
            },
        ],
    };

// Add the HighchartsReact component within your JSX to display the pie chart.

  return (
      <div>
      {paidCount || unpaidCount ? (
          <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
      ) : (
          <p>No data available yet!"</p>
      )}
      </div>
  );
};

export default Payout;
