// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Map from '../components/Map';
import Sidebar from '../components/Sidebar';
import NavBar from '../components/NavBar';
import TopSummary from '../components/TopSummary';
import ContractSummary from '../components/ContractSummary';
import TokenSummary from '../components/HomeSummary';

const projects_data = "https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-ecofutures-api/getEcoFuturesContracts";

function MapPage() {
    const [projectData, setProjectData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);


    const handleAddressClick = (address) => {
        setSelectedAddress(address);
    };

    useEffect(() => {
        fetch(projects_data)
            .then(response => response.json())
            .then(data => {
                const transformedData = {
                    type: 'FeatureCollection',
                    features: data.data.map(project => ({
                        type: 'Feature',
                        geometry: project.requestBody.geometry.features[0].geometry,
                        properties: {
                            ...project.requestBody.geometry.features[0].properties,
                            contractAddress: project.contractAddress,
                            requestIpfsCid: project.requestIpfsCid,
                            ethChainId: project.ethChainId
                        }
                    }))
                };
                setProjectData(transformedData.features);  // Corrected from transformedData.data
                setFilteredData(transformedData.features);  // Initialize filteredData with all features initially
            });
    }, []);

    const handleFilterChange = (filter) => {
        // Filter the data based on the selected filter criteria
        if (filter === 'all') {
            setFilteredData(projectData);
        } else {
            const filteredResults = projectData.filter((feature) => {
                return feature.properties.contractAddress.includes(filter);  // Updated the filter condition
            });
            setFilteredData(filteredResults);
        }
    };


    return (
        <div>
            <div class="container-scroller">
                <Sidebar />
                <div class="container-fluid page-body-wrapper">
                    <NavBar />
                    <div class="main-panel">
                        <div class="content-wrapper-insurance">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card1">
                                        <div class="card-body1">
                                           
                                                    <Map />
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                                        
                                               
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default MapPage;