// App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import Sidebar from '../components/Sidebar';
import NavBar from '../components/NavBar';
import Contract from '../components/Contracts';
// import TopSummary from '../components/TopSummary';

const ContractDetails = () => {
    return (
        <div>

            <div className="container-scroller">
                <Sidebar />
                <div className="container-fluid page-body-wrapper">
                    <NavBar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            
                            <Contract />
                            

                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default ContractDetails;