import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';


function Provider() {
    const { id } = useParams();
    const [providers, setProviders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(`https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api/getInsuranceContracts?insuranceProviderAddress=${id}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the response as JSON
            })
            .then((data) => {
                setProviders(data); // Update the state with the data
                setIsLoading(false);
                console.log('This is data', data);
            })
            .catch((error) => {
                console.error('Error fetching insurance product:', error);
                setIsLoading(false);
            });
    }, [id]);

    return (
        <div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-row justify-content-between">
                                {/* <h4 className="card-title mb-1">Insurance Providers</h4> */}
                               
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">{id} Contracts</h4>
                                            
                                            <div className="table-responsive">
                                                {isLoading ? (
                                                    <p>Loading...</p>
                                                ) : providers.data.length > 0 ? (
                                                <table className="table table-hover">
                                                    
                                                    <thead>
                                                        <tr>
                                                            <th>Contract Name</th>
                                                            {/* <th>Address</th> */}
                                                            <th>Dates</th>
                                                            <th>Status</th>
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {providers.data.map((provider) => (                          
                                                            <tr key={provider.insuranceContractAddress}>
                                                                <td><Link to={`/contract/${provider.insuranceContractAddress}`}>{provider.location}</Link></td>
                                                                {/* <td>{provider.insuranceContractAddress}</td> */}
                                                                <td>{provider.requestBody.start_date} to {provider.requestBody.end_date}</td>
                                                                <td>
                                                                    {provider.insuranceStatus === 'PAID' && (
                                                                    <div className="badge badge-outline-success">PAID</div>
                                                                )}
                                                                    {provider.insuranceStatus === 'NOT PAID' && (
                                                                        <div className="badge badge-outline-warning">NOT PAID</div>
                                                                    )}
                                                                </td>
                                                                
                                                            
                                                        </tr>
                                                        ))}
                                                      
                                                    </tbody>
                                                </table>
                                                ) : (
                                                    <p>No contracts available.</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}


export default Provider