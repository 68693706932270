import React from 'react';

const Modal = ({ message, onClickAction }) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <p>{message}</p>
                <br></br>
                {message.includes("XRPL") ?  <a href='https://opensource.ripple.com/docs/evm-sidechain/connect-metamask-to-xrpl-evm-sidechain/' target='blank'>See how to add XRPL EVM Sidechain to Metamask</a> : <button onClick={onClickAction}>Connect Wallet</button>}
            </div>
        </div>
    );
};

export default Modal;