import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAccount } from '../components/AccountContext';


const insuranceproviders = 'https://europe-west3-shamba-oracle-services.cloudfunctions.net/shamba-geo-insurance-api/getInsuranceProviders';
function InsuranceProviders() {

    const [providers, setProviders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { currentAccount, setCurrentAccount } = useAccount();


    useEffect(() => {
        window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
            fetch(`${insuranceproviders}?insurerAddress=${accounts[0]}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // Parse the response as JSON
                })
                .then((data) => {
                    setProviders(data); // Update the state with the data
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching insurance product:', error);
                    setIsLoading(false);
                });
        })

        window.ethereum.on('chainChanged', () => {
            window.location.reload();
        })
        window.ethereum.on('accountsChanged', () => {

            window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
                fetch(`${insuranceproviders}?insurerAddress=${accounts[0]}`)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json(); // Parse the response as JSON
                    })
                    .then((data) => {
                        setProviders(data); // Update the state with the data
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching insurance product:', error);
                        setIsLoading(false);
                    });
            })

        })
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-row justify-content-between">
                                

                            </div>
                            <div className="row">
                                <div className="col-12">

                                    <div className="card">
                                        <div className="card-body">

                                            <br></br>
                                            <h4 className="card-title">Insurance Products</h4>

                                            <div className="table-responsive">
                                                {isLoading ? (
                                                    <p>Loading...</p>
                                                ) : (
                                                    <table className="table table-hover">

                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Creation Date</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {providers.data.map((provider) => (
                                                                <tr key={provider._id}>
                                                                    <td><Link to={`/provider/${provider.insuranceProviderAddress}`}>{provider.insuranceProviderName}</Link></td>
                                                                    <td>{new Date(provider.createDate).toLocaleString()}</td>

                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>

                                            <br></br>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}


export default InsuranceProviders