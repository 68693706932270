// App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import Sidebar from '../components/Sidebar';
import NavBar from '../components/NavBar';
import ContractDeployment from '../components/DeployContracts';

const Deployment = () => {
    return (
        <div>

            <div class="container-scroller">
                <Sidebar />
                <div class="container-fluid page-body-wrapper">
                    <NavBar />
                    <div class="main-panel">
                        <div class="content-wrapper">
                            
                            <ContractDeployment />
                            

                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Deployment;